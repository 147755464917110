const defaults = {
  analyticsApp: {
    view: true,
  },
  notices: {
    view: true,
  },
  integrationsApp: {
    view: true,
  },
};

export const permissionsData = {
  knownActions: ['edit', 'view', 'create', 'disable', 'duplicate'],

  permissions: {
    admin: {
      ...defaults,
      adobe_analytics: {
        edit: true,
      },
      agency: {
        edit: true,
      },
      user: {
        edit: true,
      },
      callFlow: {
        duplicate: true,
        edit: (agency, tracker) =>
          tracker && !tracker.disabled_at && tracker.advanced_routing,
      },
      company: {
        create: true,
        disable: true,
        edit: true,
      },
      plan: {
        edit: true,
      },
      recordings: {
        view: true,
      },
      /** Deprecated, see context.callRailAware$ */
      supportLinks: {
        view: true,
      },
      navSettings: {
        view: true,
      },
      tracker: {
        create: (agency) => agency.active,
        edit: (agency, tracker) => tracker && !tracker.disabled_at,
        reactivate: (agency, tracker) => tracker && tracker.disabled_recently,
      },
      kiss_metrics: {
        edit: true,
      },
      acquisio: {
        edit: true,
      },
      bing_ads: {
        edit: true,
      },
      marketo: {
        edit: true,
      },
      hub_spot: {
        edit: true,
      },
      pipeline_deals: {
        edit: true,
      },
      webhooks: {
        edit: true,
      },
      optimizely_x: {
        edit: true,
      },
      kenshoo: {
        edit: true,
      },
      vwo: {
        edit: true,
      },
      salesforce: {
        edit: true,
      },
      salesforce_pro: {
        edit: true,
      },
      facebook: {
        edit: true,
      },
      double_click: {
        edit: true,
      },
      mixpanel: {
        edit: true,
      },
      unbounce: {
        edit: true,
      },
      marin: {
        edit: true,
      },
      slack: {
        edit: true,
      },
      ring_central: {
        edit: true,
      },
      google_analytics4: {
        edit: true,
      },
      custom: {
        edit: true,
      },
      google_adword: {
        edit: true,
      },
      mobile_click_to_call: {
        edit: true,
      },
      adf: {
        edit: true,
      },
      google_my_business: {
        edit: true,
      },
      google_ads_lead_form_extension: {
        edit: true,
      },
      google_tag_manager: {
        view: true,
      },
      javascript_snippet: {
        view: true,
      },
      housecall_pro: {
        edit: true,
      },
      cpl_manage_spend: {
        view: true,
      },
      microsoft_teams: {
        edit: true,
      },
      clio: {
        edit: true,
      },
      active_campaign: {
        edit: true,
      },
      my_case: {
        edit: true,
      },
      jobber: {
        edit: true,
      },
      mailchimp: {
        edit: true,
      },
      apple_business_connect: {
        edit: true,
      },
      service_titan: {
        edit: true,
      },
      typeform: {
        edit: true,
      },
      freshpaint: {
        edit: true,
      },
      clio_grow: {
        edit: true,
      },
    },
    manager: {
      ...defaults,
      adobe_analytics: {
        edit: true,
      },
      user: {
        edit: false,
      },
      callFlow: {
        duplicate: true,
      },
      company: {
        create: true,
        disable: true,
        edit: true,
      },
      /** Deprecated, see context.callRailAware$ */
      supportLinks: {
        view: (agency) => agency && !agency.white_label,
      },
      navSettings: {
        view: true,
      },
      tracker: {
        create: true,
        edit: (agency, tracker) => tracker && !tracker.disabled_at,
        reactivate: (agency, tracker) => tracker && tracker.disabled_recently,
      },
      kiss_metrics: {
        edit: true,
      },
      acquisio: {
        edit: true,
      },
      bing_ads: {
        edit: (agency) => agency && !agency.white_label,
      },
      marketo: {
        edit: true,
      },
      hub_spot: {
        edit: true,
      },
      pipeline_deals: {
        edit: true,
      },
      webhooks: {
        edit: true,
      },
      optimizely_x: {
        edit: true,
      },
      kenshoo: {
        edit: true,
      },
      vwo: {
        edit: true,
      },
      salesforce: {
        edit: true,
      },
      salesforce_pro: {
        edit: true,
      },
      facebook: {
        edit: (agency) => agency && !agency.white_label,
      },
      double_click: {
        edit: (agency) => agency && !agency.white_label,
      },
      mixpanel: {
        edit: true,
      },
      unbounce: {
        edit: (agency) => agency && !agency.white_label,
      },
      marin: {
        edit: true,
      },
      slack: {
        edit: true,
      },
      google_analytics4: {
        edit: true,
      },
      custom: {
        edit: true,
      },
      google_adword: {
        edit: true,
      },
      mobile_click_to_call: {
        edit: true,
      },
      adf: {
        edit: true,
      },
      google_my_business: {
        edit: true,
      },
      google_ads_lead_form_extension: {
        edit: true,
      },
      google_tag_manager: {
        view: true,
      },
      javascript_snippet: {
        view: true,
      },
      housecall_pro: {
        edit: true,
      },
      cpl_manage_spend: {
        view: true,
      },
      microsoft_teams: {
        edit: true,
      },
      clio: {
        edit: true,
      },
      active_campaign: {
        edit: true,
      },
      my_case: {
        edit: true,
      },
      jobber: {
        edit: true,
      },
      mailchimp: {
        edit: true,
      },
      apple_business_connect: {
        edit: true,
      },
      service_titan: {
        edit: true,
      },
      freshpaint: {
        edit: true,
      },
      ring_central: {
        edit: true,
      },
      clio_grow: {
        edit: true,
      },
    },
    reporting: {
      ...defaults,
      user: {
        edit: false,
      },
      /** Deprecated, see context.callRailAware$ */
      supportLinks: {
        view: (agency) => agency && !agency.white_label,
      },
      navSettings: {
        view: false,
      },
      tracker: {
        create: false,
      },
      kiss_metrics: {
        edit: false,
      },
      acquisio: {
        edit: false,
      },
      bing_ads: {
        edit: false,
      },
      marketo: {
        edit: false,
      },
      hub_spot: {
        edit: false,
      },
      pipeline_deals: {
        edit: false,
      },
      webhooks: {
        edit: false,
      },
      optimizely_x: {
        edit: false,
      },
      kenshoo: {
        edit: false,
      },
      vwo: {
        edit: false,
      },
      salesforce: {
        edit: false,
      },
      salesforce_pro: {
        edit: false,
      },
      facebook: {
        edit: false,
      },
      double_click: {
        edit: false,
      },
      mixpanel: {
        edit: false,
      },
      unbounce: {
        edit: false,
      },
      marin: {
        edit: false,
      },
      slack: {
        edit: false,
      },
      google_analytics4: {
        edit: false,
      },
      custom: {
        edit: false,
      },
      google_adword: {
        edit: false,
      },
      mobile_click_to_call: {
        edit: false,
      },
      adf: {
        edit: false,
      },
      google_my_business: {
        edit: false,
      },
      google_ads_lead_form_extension: {
        edit: false,
      },
      google_tag_manager: {
        view: false,
      },
      javascript_snippet: {
        view: false,
      },
      housecall_pro: {
        edit: false,
      },
      cpl_manage_spend: {
        view: false,
      },
      microsoft_teams: {
        edit: false,
      },
    },
    agent_only: {
      ...defaults,
      analyticsApp: {
        view: false,
      },
      notices: {
        view: false,
      },
      integrationsApp: {
        view: false,
      },
    },
  },
};
